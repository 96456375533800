import React from "react";
import { connect } from 'react-redux';

// nodejs library that concatenates classes
import classnames from "classnames";
import $ from 'jquery';
import api from './../../../Api.js';
import NotificationAlert from "react-notification-alert";
import ClipLoader from 'react-spinners/ClipLoader';

// reactstrap components
import {UncontrolledAlert,Button,CardHeader,FormGroup,Form,Input,InputGroupAddon,InputGroupText,InputGroup,Row,Col} from "reactstrap";
 
import QrReader from 'react-qr-reader';
import { RingLoader } from 'react-spinners';
import { load } from 'recaptcha-v3';

 
var recaptcha;

class Login extends React.Component {

  constructor(props){
    super(props);
    this.state = {errorRecoverd:"",email:"",password:"",erroResetcode:"",showForm:true,loginErro:"",logando:false,qrcode:false,user:false,erroQRcode:"",esqueciSenha:false,sendResquestEmail:false};
    this.verifyUser = this.verifyUser.bind(this);
    this.requestRecoverPassword = this.requestRecoverPassword.bind(this);
    this.verifyCodeRecover = this.verifyCodeRecover.bind(this);
    this.resetPassword = this.resetPassword.bind(this);

    window.loginPage = this;

  }

  async login(event){

      if(event !== undefined){
        event.preventDefault();
      }
      
      this.setState({logando:true});

      var obj = {email:this.state.email,password:this.state.password};
      var token = "";

      if(window.app.state.confs.recaptcha_status === "1"){
 
        token = await recaptcha.execute('login');
        console.log('token: '+token);
        obj.token = token;

      }
      
      api.login(obj).then((res) => {

        this.props.loginSuccess(res.data.session,res.data.user);

        res.data.user.session = res.data.session
        this.props.dispatch({type:"SET_DATA_USER",data:res.data.user});

        setTimeout(function(){ this.setState({logando:false}); }.bind(this), 1200);
      }).catch(error => {
        
          //console.log(error.data);
          //console.log(error);
          //console.log(error.response);
          if(error.response.status === 400){ this.setState({loginErro:""}); this.setState({loginErro:error.response.data[0].msg}); }
          this.setState({logando:false});

      });

  }

  //para verificar se existe um usuario com este e-mail ou usuario
  async verifyUser(event){
    
    return false; 
    
    /*var a = await api.searchUserLogin(event.target.value).then((res) => {
    
      this.setState({user:res.data});

    }).catch(error => { 

      this.setState({user:false});
      //console.log(error.data);
      //console.log(error);
      //console.log(error.response); 
      return false; 
 
    });

    return a;*/
 
  }

 

  async requestRecoverPassword(){
    
    if(this.state.sendResquestEmail === true){ return false; }
    
      await this.setState({sendResquestEmail:true});

      var val = $("#email").val();
      if(val === ""){

        await this.setState({errorRecoverd:"O e-mail não pode ficar em branco."}); 
        await this.setState({sendResquestEmail:false});
        return false;

      }

      var token = "";
      if(window.app.state.confs.recaptcha_status === "1"){
 
        token = await recaptcha.execute('recoverpassword');
        console.log('token: '+token);
        //obj.token = token;

      }



      api.sendRecoverPassWord(val,token).then((res) => {

        //let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Sucesso!</span><span data-notify="message">{this.props.translate['codigo_verificacao_enviado']}</span></div>),type: 'success',icon: "fas fa-exclamation",autoDismiss: 7};
        //this.refs.notificationAlert.notificationAlert(options);
        
        window.swal.fire('Sucesso!',this.props.translate['codigo_verificacao_enviado'],'success');
        this.setState({sendResquestEmail:false});

      }).catch(async error => {

         // console.log(error.data);
         // console.log(error);
         // console.log(error.response);

         if(error.response !== undefined){

          if(error.response.status === 400){ 

            await this.setState({errorRecoverd:""}); 
            await this.setState({errorRecoverd:error.response.data.msg}); 
          // console.log(error.response.msg);

          }

         }
          

         await this.setState({sendResquestEmail:false});

      });

  }

  //Para verificar o código de redefinição de senha
  async verifyCodeRecover(codigo){

    console.log('verificou codigo'+codigo);
    this.setState({coderecover:codigo});

    await api.verifyCodeRecover(codigo).then((res) => {
    
      this.setState({recoverPassuser:res.data});
      console.log(res.data);

    }).catch(error => { 
    
      if(error.response.status === 400){ 

        let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Ops!</span><span data-notify="message">{error.response.data.msg}</span></div>),type: 'danger',icon: "fas fa-exclamation",autoDismiss: 7};
        this.refs.notificationAlert.notificationAlert(options);

      }


      this.setState({showForm:true});
      console.log(error.data);
      console.log(error);
      console.log(error.response); 
      return false; 
 
    });

  }

  //Para resetar a senha
  async resetPassword(event){

    event.preventDefault();
    
    var obj = {};

    obj.code = this.state.coderecover;
    obj.password = $("#newpass").val();
    obj.confirmpassword = $("#confirmnewpass").val();

    console.log(obj);

    await api.resetPassword(obj).then((res) => {
    
      this.setState({recoverPassuser:undefined,showForm:true});
      let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Sucesso!</span><span data-notify="message">{this.props.translate['senha_redefinida']}</span></div>),type: 'success',icon: "fas fa-exclamation",autoDismiss: 7};
      this.refs.notificationAlert.notificationAlert(options);

    }).catch(async error => { 
     
      console.log(error.data);
      console.log(error);
      console.log(error.response); 
 
      if(error.response.status === 400){ 

        await this.setState({erroResetcode:""}); 
        await this.setState({erroResetcode:error.response.data.msg}); 

      }

      return false; 
 
    });

  }


  async componentDidMount(){

     

    var url = window.location.pathname;
    url = url.split('/');
    if(url[1] === "recoverpassword" & url[2] !== undefined){

      console.log('esta tentando recuperar a senha');

      this.verifyCodeRecover(url[2]);
      this.setState({showForm:false});

    }else{

      this.setState({showForm:true});

    }
    
    window.$("#superBg").css('background','url('+sessionStorage.getItem('backend')+''+window.app.state.confs.background+')');
    window.$("#superBg").fadeIn(0);
    window.$("#superBg").find('div').css('background-color','rgba(0, 0, 0, 0.17)');
    window.$("#superBg").css('background-size','cover');

    if(window.app.state.confs.recaptcha_status === "1"){

      recaptcha = await load(window.app.state.confs.recaptcha_clientkey);

    }


    //verificar se tem login e senha na barra
    var invi = window.parseQueryString();
    if(invi.email !== undefined){

      if(typeof invi.email === "string"){ this.state.email = invi.email; }
      
    }

    if(invi.password !== undefined){

      if(typeof invi.password === "string"){ this.state.password = invi.password; }
      
    }

    
    if(invi.email !== undefined && invi.password !== undefined){

      this.login();
      
    }

  }

  componentWillUnmount(){

    window.$("#superBg").css('background','');
    window.$("#superBg").fadeOut(0);
    window.$("#superBg").find('div').css('background-color','rgba(0, 0, 0, 0.60)');
    window.$("#superBg").css('background-size','cover');

  }

  render() {

    var translate = this.props.translate;
    
    return (
      <React.Fragment>
      <div className="rna-wrapper"><NotificationAlert ref="notificationAlert" /></div> 
      <div style={{background: 'linear-gradient(-45deg, '+window.app.state.confs.headerDegrade1+' 0, '+window.app.state.confs.headerDegrade2+' 100%)'}} className="header py-7 py-lg-8 pt-lg-9"><div className="container"><div className="header-body text-center mb-7"><div className="justify-content-center row"><div className="px-5 col-md-8 col-lg-6 col-xl-5"><h1 className="text-white">Bem vindo!</h1><p className="text-lead text-white">Use esse formulário para fazer login ou recuperar sua conta.</p></div></div></div></div><div className="separator separator-bottom separator-skew zindex-100"><svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" version="1.1" viewBox="0 0 2560 100" x="0" y="0"><polygon className="fill-default" points="2560 0 2560 100 0 100"></polygon></svg></div></div>

        <div className="justify-content-center " style={{display:'flex'}}>
         { //<div className="" hidden>
            //<div className="page-brand-info">
              //<div className="brand" style={{height:"160px"}}>
              //<img alt="..." className="brand-img img-responsive logo_escola" src={sessionStorage.getItem('backend')+window.app.state.confs.logo} style={{height:'60px'}} />
              //</div>
            //<p className="font-size-20" style={{color:"#fff",marginTop:"20px"}} dangerouslySetInnerHTML={{__html:window.app.state.confs.slogan}} ></p>
           //</div>
          //</d/iv>
          }
          { //className="loginRight" style={{ display:'flex',alignItems:'center',justifyContent:'center',backgroundColor:'#f7fafc !important',padding:'60px 60px 0px'}}
          }
          
          <div className="logoaqui">
            <img alt="..." style={{maxHeight:'52px'}} src={window.app.state.confs.internal_logo} />
          </div>
          <style>{'.logoaqui{ padding: 15px; justify-content: center; display: flex;position: absolute; top: 35px; background: #fff; width: 320px; height: 82px; border-radius: 9px; box-shadow: 0px 1px 5px -5px #000; }'}</style>
                    
          <div className="col-md-7 col-lg-5" style={{marginTop:"-275px", display:'flex',alignItems:'center',justifyContent:'center',backgroundColor:'#f7fafc !important',padding:'60px 60px 0px'}}>
          {

            (this.state.recoverPassuser !== undefined) &&
            <React.Fragment>
              <div className="justify-content-center" >
                <div className="card-profile bg-secondary mt-5 card">
               
                  <div className="px-5 card-body" style={{minWidth:'400px'}}>
                    <div className="text-center mb-4"><h3>Olá {this.state.recoverPassuser.name}!</h3></div>
                    {

                      (this.state.erroResetcode !== "") &&
                      <UncontrolledAlert color="danger" className="animated tada" ><span className="alert-text ml-1"><strong>Ops!</strong> {this.state.erroResetcode}</span></UncontrolledAlert>
                    
                    }
                    <font>{this.props.translate['informe_nova_senha']}</font>
                    <form className="" onSubmit={ (event) => this.resetPassword(event) } style={{marginTop:'20px'}}>
                      <div className="form-group">
                        <FormGroup className={classnames("mb-3",{focused:this.state.focusedEmail})} >
                          <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-lock-circle-open" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input id="newpass" placeholder="Nova senha" type="password" onFocus={() => this.setState({focusedEmail:true})} onBlur={() => this.setState({ focusedEmail: false })} />
                          </InputGroup>
                        </FormGroup>

                        <FormGroup className={classnames("mb-3",{focused:this.state.rs2})} >
                          <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-lock-circle-open" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input id="confirmnewpass" placeholder="Confirme a senha" type="password" onFocus={() => this.setState({rs2:true})} onBlur={() => this.setState({ rs2: false })} />
                          </InputGroup>
                        </FormGroup>
                      </div>
                      <div className="text-center"><button style={{width:'100%'}} type="submit" className="mt-2 btn btn-primary">{this.props.translate['redefinir_senha']}!</button></div>
                    </form>
                  </div>
                </div> 
              </div>
            </React.Fragment>
          }
          {
            (this.state.esqueciSenha === true && this.state.showForm === true) &&
            <React.Fragment>

 
              <Form style={{padding:'35px',boxShadow:'0px 2px 5px -4px #000'}} className="pageLoginForm bg-secondary" role="form" type="POST" onSubmit={  (event) => { event.preventDefault(); this.requestRecoverPassword(); }} >
                <center>{this.props.translate['informe_email_recuperar_senha']}</center>
                <br/>

                {

                  (this.state.errorRecoverd !== "") &&
                  <UncontrolledAlert color="danger" className="animated tada" ><span className="alert-text ml-1"><strong>Ops!</strong> {this.state.errorRecoverd}</span></UncontrolledAlert>

                }

                <FormGroup className={classnames("mb-3",{focused:this.state.focusedEmail})} >
                  <InputGroup className="input-group-merge input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input value={this.state.email} onChange={ (event) => this.setState({email:event.target.value}) } onKeyUp={ (event) => this.verifyUser(event) } placeholder="Email" type="email" id="email" onFocus={() => this.setState({focusedEmail:true})} onBlur={() => this.setState({ focusedEmail: false })} />
                  </InputGroup>
                </FormGroup>
                <Button type="submit" color="default" style={{marginTop:'10px',width:'100%'}}> 
                  {

                    (this.state.sendResquestEmail === true) &&
                    <center><RingLoader sizeUnit={"px"} size={20} color={'#fff'} loading={this.state.loading} /></center>

                  }{

                    (this.state.sendResquestEmail === false ) &&
                    <font>{this.props.translate['recuperar_senha']}</font>

                  }
                </Button>  
                <Col md={12}><center><Button onClick={ () => this.setState({esqueciSenha:false}) } style={{marginTop:'10px'}} color="secondary" size="sm" type="button"> <i className="fas fa-undo-alt"></i> {this.props.translate['voltar']} </Button></center></Col>
              </Form>
            </React.Fragment>
          }{
            (this.state.qrcode === false && this.state.esqueciSenha === false && this.state.showForm === true) &&
            <React.Fragment>
              <Form style={{padding:'35px',boxShadow:'0px 2px 5px -4px #000'}} className="pageLoginForm bg-secondary" role="form" type="POST" onSubmit={  (event) => this.login(event) } >
                {
                  (this.state.user !== false) &&
                  <div style={{height:'120px'}}>
                    <div className="justify-content-center row">
                      <div className="order-lg-2 col-lg-3">
                        <div className="card-profile-image">
                          <img alt="..." className="rounded-circle border-secondary" src={this.state.user.photo}/>
                        </div>
                      </div>
                    </div>
                    <div className="text-center mb-4" style={{marginTop:'75px'}}><h3>{this.props.translate['bem_vindo']}! {this.state.user.name}</h3></div>
                  </div>
                }{
                  (this.state.loginErro !== "") &&
                  <UncontrolledAlert color="danger" className="animated tada" ><span className="alert-text ml-1"><strong>Ops!</strong> {this.state.loginErro}</span></UncontrolledAlert>
                }
                {

                  (this.state.user === false) &&
                  <div className="text-center mb-4"><h3>{this.props.translate['area_de_login']}</h3></div>

                }

                {

                  (window.app.state.confs.license.status === 1) &&
                  <React.Fragment>
                    <FormGroup className={classnames("mb-3",{focused:this.state.focusedEmail})} >
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                          <i className="fas fa-user"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input value={this.state.email} onChange={ (event) => this.setState({email:event.target.value}) } onKeyUp={ (event) => this.verifyUser(event) } placeholder={this.props.translate['email_ou_usuario']} type="text" id="email" onFocus={() => this.setState({focusedEmail:true})} onBlur={() => this.setState({ focusedEmail: false })} />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup className={classnames({focused:this.state.focusedPassword})} >
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input value={this.state.password} onChange={ (event) => this.setState({password:event.target.value}) } placeholder={this.props.translate['senha']} type="password" id="password" onFocus={() => this.setState({focusedPassword:true})} onBlur={() => this.setState({ focusedPassword:false}) }/>
                      </InputGroup>
                    </FormGroup>
                    <Row>
                        <Col md={12} sm={12} xs={12}>
                          <center>

                            {

                              (this.state.logando === false) &&
                              <Button color="primary" type="submit" style={{marginTop:'10px',width:'100%'}}>{translate['entrar']} <i className="fas fa-key"></i></Button>

                            }

                            {

                              (this.state.logando === true) &&
                              <React.Fragment>
                                <ClipLoader sizeUnit={"px"} size={47} color={'#849bc7'} loading={true}/>
                              </React.Fragment>

                            }
                            
                            {

                              (this.state.loading === false) &&
                              <React.Fragment></React.Fragment>

                            }

                            <br/>
                            {
                              (this.state.user !== false) &&
                              <Button onClick={ () => this.setState({qrcode:true}) } color="default" type="button" style={{marginTop:'10px',width:'100%'}}>Entrar com QR code <i className="fas fa-qrcode"></i></Button>
                            }
                          </center>
                          <Col md={12}>
                            <center><div onClick={ () => this.setState({esqueciSenha:true}) } style={{marginTop:'10px',fontSize:'12px',color:'#8898aa',cursor:'pointer'}}  >{translate['esqueci_minha_senha']}</div></center>
                          </Col>
                        </Col>
                    </Row>
                  </React.Fragment>

                }

                {

                  (window.app.state.confs.license.status === 0) &&
                  <React.Fragment>
                    <div className="alert alert-warning fade show" role="alert" style={{textAlign:'center'}}>A licença foi suspensa.</div>
                  </React.Fragment>

                }
                
                
                <div className="text-center"></div>
                <CardHeader className="bg-transparent" style={{padding:'0rem 0rem',marginTop:'22px',paddingTop:'0px',marginBottom:'0px',paddingBottom:'0px'}}>
                  <div className="text-muted text-center mt-2 mb-3"><small>{translate['escolha_uma_linguagem']}</small></div>
                  <div className="btn-wrapper text-center">
                    {
                      this.props.languages.map((value,key)=>{
                        var img = "/flags/"+value.img;
                        return(
                          <Button key={key+'_language'} onClick={ () => window.app.getLanguageTexts(value.file) } style={{marginBottom:'15px'}} className="btn-neutral btn-icon" color="default" >
                            <span className="btn-inner--icon mr-1"><img alt="..." src={img} /></span>
                            <span className="btn-inner--text">{value.name}</span>
                          </Button>)
                      })
                    }
                  </div>
                </CardHeader>
              </Form>
            </React.Fragment>
          }
          </div>
        </div>

  <style>{'body{ background-color:#172b4d; }'}</style>

      </React.Fragment>);
  }
}

export default connect(state =>({

  languages: state.language,
  translate: state.translate

}))(Login);