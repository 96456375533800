import React, { useRef } from 'react';
import styles from './../assets/scss/modules/loadPage.module.scss';
import Lottie from 'react-lottie';

//animations
import loadingCircleData from './../assets/animations/loadingCircleWhite.json';
import loadingCircleDataRed from './../assets/animations/loadingCircle.json';

export default function LoadPage({ name = 'conteúdo', hidden = false }) {

    const loadingCircleOptions = useRef({
        loop: true,
        autoplay: true,
        animationData: loadingCircleDataRed,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    });


    return (
        <>
            <div hidden={hidden} className={styles.container}>
                <Lottie
                    width="5.5rem"
                    height="5.5rem"
                    isClickToPauseDisabled={true}
                    options={loadingCircleOptions.current}
                />
                <p>{`Carregando ${name}`}</p>
            </div>
        </>
    )
}

export function LoadContent({ name = 'conteúdo', hidden = false }) {

    const loadingCircleOptionsRed = useRef({
        loop: true,
        autoplay: true,
        animationData: loadingCircleDataRed,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    });

    return (
        <>
            <div hidden={hidden} className={styles.containerContent}>
                <Lottie
                    width="5.5rem"
                    height="5.5rem"
                    isClickToPauseDisabled={true}
                    options={loadingCircleOptionsRed.current}
                />
                <p>{`Carregando ${name}`}</p>
            </div>
        </>
    )
}