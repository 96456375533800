import axios from 'axios';

window.axios = axios;
//Não verifica o certificado ssl
var api = axios.create({secure:false});

//var backend = 'http://'+window.location.hostname+':3002';

var apis = {
	

	// Lista os idiomas disponíveis
	listLanguages: () => api.get('/language/index.json'),
	
	// Retorna o texto no idioma conforme definido pelo usuário
	languageText: (file) => api.get('/language/'+file),

	/**
	 * PT-BR: Função para retornar o endereço do CEP
	 * EN: Function to return zip address
	*/
	getZip: (value) => api.get(`https://viacep.com.br/ws/${value}/json/`),

	// Retorna a lista de franquias
	//getCompanies: () => api.get(backend+'/company'),

	// Envia as informações preenchidas da franquia para banco de dados
	//postCompany: (data) => api.post(backend+"/company/", data),

	// Atualiza as informações da franquia no banco de dados
	//putCompany: (data,id) => api.put(backend+"/company/"+id, data),

	// Delete a franquia selecionada do banco de dados
	//deleteCompany: (id) => api.delete(backend+"/company/"+id),
	 
	verifyBackend: (file) => api.get('/serverlist/'+file+'.json'),
	verifyBackend2: (url) => api.get(url),

	login: (data) => api.post(sessionStorage.getItem('backend')+'/auth/password',data),
	users: () => api.get(sessionStorage.getItem('backend')+'/user',{headers: {session: sessionStorage.getItem('session')}}),

	usersCompany: (company) => api.get(sessionStorage.getItem('backend')+'/user/company/'+company,{headers: {session: sessionStorage.getItem('session')}}),

	levelAcess: () => api.get(sessionStorage.getItem('backend')+'/acessLevel'),
	
	//Para carregar as configurações do sistema
	loadConfig: (file) => api.get(sessionStorage.getItem('backend')+'/configuration/'),

	//para atualizar os dados de um usuario
	updateUser: (id,data) => api.put(sessionStorage.getItem('backend')+'/user/'+id,data,{headers: {session: sessionStorage.getItem('session')}}),
	configurationall: () => api.get(sessionStorage.getItem('backend')+'/configurationall',{headers: {session: sessionStorage.getItem('session')}}),
	 

	getCrf: (year,data) => api.post(sessionStorage.getItem('backend')+'/report/crf/'+year,data,{headers: {session: sessionStorage.getItem('session')}}),

	//Parar criar um novo usuario
	postUser: (data) => api.post(sessionStorage.getItem('backend')+'/user/',data,{headers: {session: sessionStorage.getItem('session')}}),

	//Para remover o usuario
	deleteUser: (id) => api.delete(sessionStorage.getItem('backend')+'/user/'+id,{headers: {session: sessionStorage.getItem('session')}}),

	//Para enviar arquivo
	uploadFile: (data) => api.delete(sessionStorage.getItem('backend')+'/upload/',data,{headers: {session: sessionStorage.getItem('session')}}),
 
	//Para listar todos os cursos
	getAllcourses: () => api.get(sessionStorage.getItem('backend')+'/course/',{headers: {session: sessionStorage.getItem('session')}}),

	getAllClassRoom: (data) => api.get(sessionStorage.getItem('backend')+'/classroom'+(data !== undefined ? data:''),{headers: {session: sessionStorage.getItem('session')}}),

	getAllClassRoomCompany: (company) => api.get(sessionStorage.getItem('backend')+'/classroom/company/'+company,{headers: {session: sessionStorage.getItem('session')}}),
	
	//Para verificar o acesso do usuario no sisstema
 	verifyAcess: (id) => api.get(sessionStorage.getItem('backend')+'/auth/verify',{headers: {session: sessionStorage.getItem('session')}}),
 	
 	//Para chegar a licença
 	checkLicense: () => api.get(sessionStorage.getItem('backend')+'/license/check',{headers: {session: sessionStorage.getItem('session')}}),

 	//Para pegar as permissões
	getLevelAcess: () => api.get(sessionStorage.getItem('backend')+'/acessLevel',{headers: {session: sessionStorage.getItem('session')}}),
  
	//Para editar uma turma
	postClassRoom: (data) => api.post(sessionStorage.getItem('backend')+'/classroom/',data,{headers: {session: sessionStorage.getItem('session')}}),

	//Para pegar os dados de uma turma
	getClassRoom: (id) => api.get(sessionStorage.getItem('backend')+'/classroom/'+id,{headers: {session: sessionStorage.getItem('session')}}),

	//Para remover uma turma
	putClassRoom: (id,data) => api.put(sessionStorage.getItem('backend')+'/classroom/'+id,data,{headers: {session: sessionStorage.getItem('session')}}),

	//Para remover a turma
	deleteClassRoom: (id) => api.delete(sessionStorage.getItem('backend')+'/classroom/'+id,{headers: {session: sessionStorage.getItem('session')}}),

	//Para pesquisar alunos para colocar na turma
	searchUserClassRoomTerm: (id,term) => api.get(sessionStorage.getItem('backend')+'/classroom/searchuser/'+id+'?term='+term,{headers: {session: sessionStorage.getItem('session')}}),

	//Para pegar os alunos que ja estão na turma
	searchUserClassRoom: (id) => api.get(sessionStorage.getItem('backend')+'/classroom/user/'+id,{headers: {session: sessionStorage.getItem('session')}}),

	//Para adicionar uma usuario na class
	addUserClassRoom: (data) => api.post(sessionStorage.getItem('backend')+'/classroom/user/add',data,{headers: {session: sessionStorage.getItem('session')}}),

	//Para remover um usuário da class
	deleteUserClassRoomApi: (classroom,user) => api.delete(sessionStorage.getItem('backend')+'/classroom/user/remove?user='+user+'&classroom='+classroom+'',{headers: {session: sessionStorage.getItem('session')}}),

	//Para pegar as turmas que eu faço parte
	getMyClassRooms: () => api.get(sessionStorage.getItem('backend')+'/myclassroom/',{headers: {session: sessionStorage.getItem('session')}}),

	//Para pegar as turmas que eu faço parte e da compania
	getMyClassRoomsCompany: (company) => api.get(sessionStorage.getItem('backend')+'/myclassroom/company/'+company,{headers: {session: sessionStorage.getItem('session')}}),

 	//Para pegar as units de um curso
	getUnitsCourse: (id)=> api.get(sessionStorage.getItem('backend')+'/course/units/'+id,{headers: {session: sessionStorage.getItem('session')}}),

	//Para pegar as lessons de uma unit
	getLessonsUnit: (id) => api.get(sessionStorage.getItem('backend')+'/lesson/unit/'+id,{headers: {session: sessionStorage.getItem('session')}}),

	//Para pegar o progresso do usuario em uma lesson
	getLessonsProgress: (classron,lesson,user) => api.get(sessionStorage.getItem('backend')+'/progress/'+classron+'/'+lesson+'/'+user,{headers: {session: sessionStorage.getItem('session')}}),

	//Para pegar o progresso do usuario em uma lesson
	getLesson: (lesson,extra="") => api.get(sessionStorage.getItem('backend')+'/lesson/'+lesson+extra,{headers: {session: sessionStorage.getItem('session')}}),

	//Par postar os dados de um progresso
	postProgress: (data) => api.post(sessionStorage.getItem('backend')+'/progress/',data,{headers: {session: sessionStorage.getItem('session')}}),
 	
	//Para pegar tudo de uma turma
	getAcessoClassRoom: (classroom,user) => api.get(sessionStorage.getItem('backend')+'/myclassroom/progress/'+classroom+'/'+user,{headers: {session: sessionStorage.getItem('session')}}),

	//Para pegar o progresso de uma turma numa unit
	getProgressUnitClassroom: (classroom,unit,all) => api.get(sessionStorage.getItem('backend')+'/progress/'+classroom+'?unit='+unit+all,{headers: {session: sessionStorage.getItem('session')}}),

	//Para pegar o progresso de uma turma numa unit
	savePreference: (obj) => api.put(sessionStorage.getItem('backend')+'/user/savesetting',obj,{headers: {session: sessionStorage.getItem('session')}}),

	//Para setar o qrcode do usuário
	setQRcode: (user,obj) => api.put(sessionStorage.getItem('backend')+'/user/saveqrcode/'+user,obj,{headers: {session: sessionStorage.getItem('session')}}),

	//Para verificar se o usuario existe... usado na tela de login
	searchUserLogin: (user) => api.get(sessionStorage.getItem('backend')+'/auth/exist_user/'+user),

	//Para fazer o login via qrcode
	loginQRcode: (obj) => api.post(sessionStorage.getItem('backend')+'/auth/qrcode',obj),

	//Para fazer o login via qrcode
	sendRecoverPassWord: (email,token) => api.post(sessionStorage.getItem('backend')+'/auth/recoverpassword/'+email+'?token='+token),

	//Para verificar o código de redifinição de senha
	verifyCodeRecover: (codigo) => api.get(sessionStorage.getItem('backend')+'/auth/verifycoderecover/'+codigo),

	//Para enviar a nova senha para o servidor
	resetPassword: (obj) => api.put(sessionStorage.getItem('backend')+'/auth/resetpassword/',obj),

	//Para enviar a nova senha para o servidor
	savePhoto: (user,obj) => api.put(sessionStorage.getItem('backend')+'/user/updatephoto/'+user,obj,{headers: {session: sessionStorage.getItem('session')}}),

	//Para salvar as configurações do sistema
	saveConfiguration: (obj) => api.put(sessionStorage.getItem('backend')+'/configuration/',obj,{headers: {session: sessionStorage.getItem('session')}}),

	saveConfigurationLmsApi: (obj) => api.put(sessionStorage.getItem('backend')+'/configurationlmsapi/',obj,{headers: {session: sessionStorage.getItem('session')}}),

	 

	//Para alterar a senha do usuário
	changePassword: (obj) => api.put(sessionStorage.getItem('backend')+'/user/changepassowrd',obj,{headers: {session: sessionStorage.getItem('session')}}),

	//Para pegar o quiz da lesson
	getQuizLesson: (quiz) => api.get(sessionStorage.getItem('backend')+'/lesson/quiz/'+quiz,{headers: {session: sessionStorage.getItem('session')}}),

	//para pegar o progresso do quiz do usuario
	getProgressQuiz: (classroom,quiz,lesson) => api.get(sessionStorage.getItem('backend')+'/quiz/getProgress/?quiz='+quiz+'&classroom='+classroom+'&lesson='+lesson,{headers: {session: sessionStorage.getItem('session')}}), 

	//Para salvar o cache do progresso do quiz
	postProgressQuiz: (obj) => api.post(sessionStorage.getItem('backend')+'/quiz/saveProgress',obj,{headers: {session: sessionStorage.getItem('session')}}), 

 	//Para pegar somente os calendario que eu tenho acesso
	getMyCalendar: (data) => api.get(sessionStorage.getItem('backend')+'/calendar/mylist',{headers: {session: sessionStorage.getItem('session')}}),

	//Para pegar todos os calendarios
	getCalendar: (data) => api.get(sessionStorage.getItem('backend')+'/calendar/',{headers: {session: sessionStorage.getItem('session')}}),

	//Para cadastrar um calendario
	postCalendar: (data) => api.post(sessionStorage.getItem('backend')+'/calendar/',data,{headers: {session: sessionStorage.getItem('session')}}),

	//Para reditar um calendario
	putCalendar: (id,data) => api.put(sessionStorage.getItem('backend')+'/calendar/'+id,data,{headers: {session: sessionStorage.getItem('session')}}),

	//Para remover um calendario
	deleteCalendar: (calendar) => api.delete(sessionStorage.getItem('backend')+'/calendar/'+calendar,{headers: {session: sessionStorage.getItem('session')}}),

	//Para pegar o tempo investido para gerar o chat
	chartTimeInvested: (user) =>  api.get(sessionStorage.getItem('backend')+'/chart/timeinvested/'+user,{headers: {session: sessionStorage.getItem('session')}}),

	//Para pegar o desempenho dele no quiz
	chartQuizperFormance: (user) =>  api.get(sessionStorage.getItem('backend')+'/chart/quizperformance/'+user,{headers: {session: sessionStorage.getItem('session')}}),

	//Para verificar no json se esta tudo certo antes de inserir no banco de dados
	userCheckXlsx: (data) =>  api.post(sessionStorage.getItem('backend')+'/user/checkxlsx/',data,{headers: {session: sessionStorage.getItem('session')}}),

	classRoomCheckXlsx: (data) =>  api.post(sessionStorage.getItem('backend')+'/classroom/checkxlsx/',data,{headers: {session: sessionStorage.getItem('session')}}),

	getClassRoomAccessOnline: (idonline) =>  api.get(sessionStorage.getItem('backend')+'/classroom/idonlineaccess/'+idonline,{headers: {session: sessionStorage.getItem('session')}}),

	//Para pegar todo o tempo nas lesson de uma unit
	chartTimeLessonsUnit: (user,classroom,unit) =>  api.get(sessionStorage.getItem('backend')+'/chart/timelessonsunit/'+user+'?unit='+unit+'&classroom='+classroom,{headers: {session: sessionStorage.getItem('session')}}),
	
	//Para pegar todos os tempos na unit da turma
	chartTimeUnit: (user,classroom) =>  api.get(sessionStorage.getItem('backend')+'/chart/timeunit/'+user+'?&classroom='+classroom,{headers: {session: sessionStorage.getItem('session')}}),

	//Para pegar 
	chartQuizUserUnit: (user,classroom,unit) => api.get(sessionStorage.getItem('backend')+'/chart/quizavaliation/'+user+'?unit='+unit+'&classroom='+classroom,{headers: {session: sessionStorage.getItem('session')}}),

	//Para listar todas as unidades
	getListCompany: () =>  api.get(sessionStorage.getItem('backend')+'/company/',{headers: {session: sessionStorage.getItem('session')}}),

	//Para cadastrar uma unidade
	//postCompany: (obj) =>  api.post(sessionStorage.getItem('backend')+'/company/',obj,{headers: {session: sessionStorage.getItem('session')}}),

	//Para editar uma unidade
	//putCompany: (id,obj) =>  api.put(sessionStorage.getItem('backend')+'/company/'+id,obj,{headers: {session: sessionStorage.getItem('session')}}),

	//Para remover uma unidade
	//deleteCompany: (id) =>  api.delete(sessionStorage.getItem('backend')+'/company/'+id,{headers: {session: sessionStorage.getItem('session')}}),

	//Para pegar as turmas de uma unidade
	getUsersCompany: (id) =>  api.get(sessionStorage.getItem('backend')+'/company/listusers/'+id,{headers: {session: sessionStorage.getItem('session')}}),

	//Para pegar as turmas de uma unidade
	getClassRoomCompany: (id) =>  api.get(sessionStorage.getItem('backend')+'/company/classroom/'+id,{headers: {session: sessionStorage.getItem('session')}}),
	
	//Para pegar todos os gestores da unidade
	searchManagersCompanyAll: (company) =>  api.get(sessionStorage.getItem('backend')+'/company/searchallmanagers/'+company,{headers: {session: sessionStorage.getItem('session')}}),

	//Para pegar os gestores de uma unidade
	searchManagersCompanyTerm: (id,term) =>  api.get(sessionStorage.getItem('backend')+'/company/searchmanagers/'+id+'?term='+term,{headers: {session: sessionStorage.getItem('session')}}),

	//Para pegar os gestores de uma unidade
	addUserCompany: (company,user) =>  api.post(sessionStorage.getItem('backend')+'/company/adduser/'+company,{user:user},{headers: {session: sessionStorage.getItem('session')}}),

	//Para pegar os gestores de uma unidade
	deleteUserCompany: (company,user) =>  api.delete(sessionStorage.getItem('backend')+'/company/removeuser/'+company+'?user='+user,{headers: {session: sessionStorage.getItem('session')}}),
	
	//Par pegar as unidades que eu tenho acesso
	getMyCompanys: () =>  api.get(sessionStorage.getItem('backend')+'/company/mycompanys/',{headers: {session: sessionStorage.getItem('session')}}),

	//Para pegar os meus estudantes
	getMyStudents: () =>  api.get(sessionStorage.getItem('backend')+'/widget/mystudents',{headers: {session: sessionStorage.getItem('session')}}),
	 
	//Para pegar o usuários online
	getUsersOnline: () =>  api.get(sessionStorage.getItem('backend')+'/chat/usersonline',{headers: {session: sessionStorage.getItem('session')}}),

	//Para pegar o link de acesso a engine
	getAccessEngine:() =>  api.get(sessionStorage.getItem('backend')+'/accessengine',{headers: {session: sessionStorage.getItem('session')}}),

	//Para pegar o total de tempo em tudo
	getTotalTimeUser:(user) =>  api.get(sessionStorage.getItem('backend')+'/chart/totalTime/'+user,{headers: {session: sessionStorage.getItem('session')}}),

	//Para trocar a senha de algum usuário
	changeUserPassword:(user,data) =>  api.put(sessionStorage.getItem('backend')+'/user/changeuserpassword/'+user,data,{headers: {session: sessionStorage.getItem('session')}}),
 
	//Para pegar o progresso da turma em todas as units
	getProgressAllUnits:(classroom) =>  api.get(sessionStorage.getItem('backend')+'/totalprogress/classroom/'+classroom,{headers: {session: sessionStorage.getItem('session')}}),

	//Para pegar os usuarios para o chat
	getUsersChat:() => api.get(sessionStorage.getItem('backend')+'/chat/listMyContats',{headers: {session: sessionStorage.getItem('session')}}),

	//Para enviar uma mensagem para algum usuario
	chatSend: (data) => api.get(sessionStorage.getItem('backend')+'/chat/send',data,{headers:{session:sessionStorage.getItem('session')}}),
	
	
	

	//Para pegar os campos de cliente
	getClientGroupFields: (groupID) => api.get(sessionStorage.getItem('backend')+'/clientfield/group/'+groupID,{headers:{session:sessionStorage.getItem('session')}}),

	/*

	agora essas funções são usadas na central 
	postClientGroupFields: (data) => api.post(sessionStorage.getItem('backend')+'/clientfield/group/',data,{headers:{session:sessionStorage.getItem('session')}}),

	putClientGroupFields: (id,data) => api.put(sessionStorage.getItem('backend')+'/clientfield/group/'+id,data,{headers:{session:sessionStorage.getItem('session')}}),

	deleteClientGroupFields: (id) => api.delete(sessionStorage.getItem('backend')+'/clientfield/group/'+id,{headers:{session:sessionStorage.getItem('session')}}),

	postClientGroupFieldsField: (data) => api.post(sessionStorage.getItem('backend')+'/clientfield/field/',data,{headers:{session:sessionStorage.getItem('session')}}),

	putClientGroupFieldsField: (id,data) => api.put(sessionStorage.getItem('backend')+'/clientfield/field/'+id,data,{headers:{session:sessionStorage.getItem('session')}}),

	updateSequenceGroupFields: (data) => api.put(sessionStorage.getItem('backend')+'/clientfield/updatesequence/',data,{headers:{session:sessionStorage.getItem('session')}}),
	*/

	//Para cadastrar um cliente
	postClient: (data) => api.post(sessionStorage.getItem('backend')+'/client/',data,{headers:{session:sessionStorage.getItem('session')}}),
	 

	//Para pegar os dados de um cliuente
	getClient: (id) => api.get(sessionStorage.getItem('backend')+'/client/'+id,{headers:{session:sessionStorage.getItem('session')}}),

	//Para atualizar um cliente
	putClient: (id,data) => api.put(sessionStorage.getItem('backend')+'/client/'+id,data,{headers:{session:sessionStorage.getItem('session')}}),



	getFinancial: (data) => api.get(sessionStorage.getItem('backend')+'/iugu/financial'+data,{headers:{session:sessionStorage.getItem('session')}}),


	sendMailNf: (id) => api.get(sessionStorage.getItem('backend')+'/nf/sendmail/'+id,{headers:{session:sessionStorage.getItem('session')}}),

	 





	
	searchClient: (order,status,search,limit,page) => api.get(sessionStorage.getItem('backend')+'/client/search?limit='+limit+'&page='+page+'&search='+search+'&status='+status+'&order='+order,{headers:{session:sessionStorage.getItem('session')}}),

	searchStudents: (order,status,search,limit,page) => api.get(sessionStorage.getItem('backend')+'/student/search?limit='+limit+'&page='+page+'&search='+search+'&status='+status+'&order='+order,{headers:{session:sessionStorage.getItem('session')}}),

	searchRegistrion: (order,status,search,limit,page) => api.get(sessionStorage.getItem('backend')+'/registration/search?limit='+limit+'&page='+page+'&search='+search+'&status='+status+'&order='+order,{headers:{session:sessionStorage.getItem('session')}}),


	getBaseApiLms: (order,status,search,limit,page) => api.get(sessionStorage.getItem('backend')+'/configurationlmsapi/base',{headers:{session:sessionStorage.getItem('session')}}),




	 







	//Para pegar um arquivo no servidor
	getFileUpload: (id) => api.get(sessionStorage.getItem('backend')+'/upload/'+id,{headers:{session:sessionStorage.getItem('session')}}),

	//Para cadastrar uma fatura
	postInvoice: (data) => api.post(sessionStorage.getItem('backend')+'/invoice/',data,{headers:{session:sessionStorage.getItem('session')}}),

	//para pegar as faturas do cliente
	getInvoiceClient: (client) => api.get(sessionStorage.getItem('backend')+'/invoice/client/'+client,{headers:{session:sessionStorage.getItem('session')}}),


	//Para pegar uma fatura especifica
	getInvoice: (id) => api.get(sessionStorage.getItem('backend')+'/invoice/'+id,{headers:{session:sessionStorage.getItem('session')}}),

	postInvoice: (data) => api.post(sessionStorage.getItem('backend')+'/invoice',data,{headers:{session:sessionStorage.getItem('session')}}),

	putInvoice: (id,data) => api.put(sessionStorage.getItem('backend')+'/invoice/'+id,data,{headers:{session:sessionStorage.getItem('session')}}),

	putInvoiceCreditCardAndDueDate: (id,data) => api.put(sessionStorage.getItem('backend')+'/invoiceupdatecreditcard/'+id,data,{headers:{session:sessionStorage.getItem('session')}}),

	 

	deleteInvoice: (id) => api.delete(sessionStorage.getItem('backend')+'/invoice/'+id,{headers:{session:sessionStorage.getItem('session')}}),

	//Para pegar os dados de um estudante
	getStudent: (id) => api.get(sessionStorage.getItem('backend')+'/student/'+id,{headers:{session:sessionStorage.getItem('session')}}),

	//Para atualizar um estudante
	putStudent: (id,data) => api.put(sessionStorage.getItem('backend')+'/student/'+id,data,{headers:{session:sessionStorage.getItem('session')}}),

	//Para cadastrar um estudante
	postStudent: (data) => api.post(sessionStorage.getItem('backend')+'/student/',data,{headers:{session:sessionStorage.getItem('session')}}),

	 
	//Para pegar os parentes de um estudante
	getStudentParentage: (student) => api.get(sessionStorage.getItem('backend')+'/student/parentage/'+student,{headers:{session:sessionStorage.getItem('session')}}),
	 
	//Para cadastrar um parentesco
	postParentage: (data) => api.post(sessionStorage.getItem('backend')+'/student/parentage/',data,{headers:{session:sessionStorage.getItem('session')}}),
	
	//Para remover o parentesco
	deleteParentage: (client,student) => api.delete(sessionStorage.getItem('backend')+'/student/parentage/?client='+client+'&student='+student,{headers:{session:sessionStorage.getItem('session')}}),


	//Para remover o cliente
	deleteClient: (client) => api.delete(sessionStorage.getItem('backend')+'/client/'+client,{headers:{session:sessionStorage.getItem('session')}}),
	 
	//Para remover o estudante
	deleteStudent: (student) => api.delete(sessionStorage.getItem('backend')+'/student/'+student,{headers:{session:sessionStorage.getItem('session')}}),

	//Para fazer uma matrícula
	postRegistration: (data) => api.post(sessionStorage.getItem('backend')+'/registration/',data,{headers:{session:sessionStorage.getItem('session')}}),

	//Para pegar as mastrículas de um cliente
	getRegistrationClient: (client) => api.get(sessionStorage.getItem('backend')+'/registration/client/'+client,{headers:{session:sessionStorage.getItem('session')}}),

	getRegistrationStudent: (student) => api.get(sessionStorage.getItem('backend')+'/registration/student/'+student,{headers:{session:sessionStorage.getItem('session')}}),
	 
	//Para pegar os cartões de crédito de um cliente
	getCreditCards: (client) => api.get(sessionStorage.getItem('backend')+'/creditcard/client/'+client,{headers:{session:sessionStorage.getItem('session')}}),

	//Para cadastrar um cartão de crédito no cliente
	postCreditCard: (data) => api.post(sessionStorage.getItem('backend')+'/creditcard/',data,{headers:{session:sessionStorage.getItem('session')}}),

	//Para editar o cartão de crédito
	putCreditCard: (id,data) => api.put(sessionStorage.getItem('backend')+'/creditcard/'+id,data,{headers:{session:sessionStorage.getItem('session')}}),
	
	//Para remover o cartão de crédito
	deleteCreditCard: (id) => api.delete(sessionStorage.getItem('backend')+'/creditcard/'+id,{headers:{session:sessionStorage.getItem('session')}}),

	//Para deletar uma matrícula
	deleteRegistration: (id) => api.delete(sessionStorage.getItem('backend')+'/registration/'+id,{headers:{session:sessionStorage.getItem('session')}}),
	
	//Para pegar as faturas de uma matrícula
	getInvoicesRegistration: (id) => api.get(sessionStorage.getItem('backend')+'/registration/listinvoices/'+id,{headers:{session:sessionStorage.getItem('session')}}),

	//Para pegar as faturas de uma matrícula
	getContractsRegistration: (id) => api.get(sessionStorage.getItem('backend')+'/registration/listcontracts/'+id,{headers:{session:sessionStorage.getItem('session')}}),

	//Para pegar as faturas de uma matrícula
	postRegistrationContract: (registration,data) => api.post(sessionStorage.getItem('backend')+'/registration/savecontract/'+registration,data,{headers:{session:sessionStorage.getItem('session')}}),

	//Para atualizar uma fatura
	putInvoice: (invoice,data) => api.put(sessionStorage.getItem('backend')+'/invoice/'+invoice,data,{headers:{session:sessionStorage.getItem('session')}}),

	//Para faturar uma matrícula
	faturarRegistration: (registration) => api.get(sessionStorage.getItem('backend')+'/registration/faturar/'+registration,{headers:{session:sessionStorage.getItem('session')}}),

	checkallnfs: () => api.get(sessionStorage.getItem('backend')+'/checkallnfs',{headers:{session:sessionStorage.getItem('session')}}),
	
	getCep: (cep) => api.get(sessionStorage.getItem('backend')+'/getcep/'+cep,{headers:{session:sessionStorage.getItem('session')}}),

	getFilesClient: (client) => api.get(sessionStorage.getItem('backend')+'/clientfile/client/'+client,{headers:{session:sessionStorage.getItem('session')}}),

	postFileClient: (data) => api.post(sessionStorage.getItem('backend')+'/clientfile/',data,{headers:{session:sessionStorage.getItem('session')}}),

	deleteFileClient: (id) => api.delete(sessionStorage.getItem('backend')+'/clientfile/'+id,{headers:{session:sessionStorage.getItem('session')}}),

	cancelInvoice: (id) => api.put(sessionStorage.getItem('backend')+'/invoice/'+id+'/cancel/',{},{headers:{session:sessionStorage.getItem('session')}}),

	postAnnotation: (data) => api.post(sessionStorage.getItem('backend')+'/annotation',data,{headers:{session:sessionStorage.getItem('session')}}),

	putAnnotation: (id,data) => api.put(sessionStorage.getItem('backend')+'/annotation/'+id,data,{headers:{session:sessionStorage.getItem('session')}}),

	deleteAnnotation: (id) => api.delete(sessionStorage.getItem('backend')+'/annotation/'+id,{headers:{session:sessionStorage.getItem('session')}}),

	getAnnotations: (client) => api.get(sessionStorage.getItem('backend')+'/annotation/client/'+client+'/',{headers:{session:sessionStorage.getItem('session')}}),

	//widgets
	getTotalRegistration: () => api.get(sessionStorage.getItem('backend')+'/widgets/totalregistration',{headers:{session:sessionStorage.getItem('session')}}),

	getTotalClients: () => api.get(sessionStorage.getItem('backend')+'/widgets/totalclients',{headers:{session:sessionStorage.getItem('session')}}),

	getTotalStudents: () => api.get(sessionStorage.getItem('backend')+'/widgets/totalstudents',{headers:{session:sessionStorage.getItem('session')}}),

	getTotalinvoice: () => api.get(sessionStorage.getItem('backend')+'/widgets/totalinvoice',{headers:{session:sessionStorage.getItem('session')}}),

	//Para pesquisar as faturas
	searchInvoice: (order,status,term,startDate,endDate,type,page,limit,all,cl_filter) => api.get(sessionStorage.getItem('backend')+'/invoice/search?page='+page+'&limit='+limit+'&term='+term+'&status='+status+'&order='+order+'&startDate='+startDate+'&endDate='+endDate+'&type='+type+''+(all === true ? '&all=1':'')+((cl_filter !== '' && cl_filter !== undefined) ? '&cl_filter='+cl_filter:''),{headers:{session:sessionStorage.getItem('session')}}), 
	
	//Para pegar as matrículas de uma turma
	listRegistrationsClassRoom: (classroom) => api.get(sessionStorage.getItem('backend')+'/registration/classroom/'+classroom,{headers:{session:sessionStorage.getItem('session')}}),

	//Para pegar os parentes de cliete
	getClientParentage: (id) => api.get(sessionStorage.getItem('backend')+'/client/parentage/'+id,{headers:{session:sessionStorage.getItem('session')}}),

	getchartvendas: (id) => api.get(sessionStorage.getItem('backend')+'/widgets/chartvendas',{headers:{session:sessionStorage.getItem('session')}}),

	getErrosInvoice: (id) => api.get(sessionStorage.getItem('backend')+'/invoice/erros/'+id,{headers:{session:sessionStorage.getItem('session')}}),

	setReadErrorInvoice: (id,status) => api.put(sessionStorage.getItem('backend')+'/invoice/erros/'+id,{status:status},{headers:{session:sessionStorage.getItem('session')}}),

	//para cobrar a fatura no cartão de crédito
	payCreditCard: (id) => api.get(sessionStorage.getItem('backend')+'/invoice/bil/'+id,{headers:{session:sessionStorage.getItem('session')}}),
	
	//Para pegar todas as mastrículas
	listRegistrations: (id) => api.get(sessionStorage.getItem('backend')+'/registration/',{headers:{session:sessionStorage.getItem('session')}}), 

	//para pegar o chat inicial
	getDataChartInvoicesDash: () => api.get(sessionStorage.getItem('backend')+'/widgets/chartvendas2',{headers:{session:sessionStorage.getItem('session')}}),
	 
	//Para reemitir a fatura
	reissueInvoice: (id,due_date) => api.post(sessionStorage.getItem('backend')+'/invoice/reissue/'+id,{due_date:due_date},{headers:{session:sessionStorage.getItem('session')}}),

	//pega uma matrícula especifica
	getRegistration: (id) => api.get(sessionStorage.getItem('backend')+'/registration/'+id,{headers:{session:sessionStorage.getItem('session')}}),

	transferRegistrationClassRoom: (registration,classroom) => api.put(sessionStorage.getItem('backend')+'/registration/transferclassroom',{registration:registration,classroom:classroom},{headers:{session:sessionStorage.getItem('session')}}),

	//Para pegar os funis cadastrados
	getFunnelList: (id) => api.get(sessionStorage.getItem('backend')+'/funnel/',{headers:{session:sessionStorage.getItem('session')}}),

	//Para pegar os dados de um funil
	getFunnel: (id) => api.get(sessionStorage.getItem('backend')+'/funnel/'+id,{headers:{session:sessionStorage.getItem('session')}}),
	getFunnel2: (id) => api.get(sessionStorage.getItem('backend')+'/funnel/'+id),
	 
	getColumnsFunnel: (id) => api.get(sessionStorage.getItem('backend')+'/funnel/column/'+id,{headers:{session:sessionStorage.getItem('session')}}),

	//para cadastrar uma coluna
	postColumn: (obj) => api.post(sessionStorage.getItem('backend')+'/funnel/column/',obj,{headers:{session:sessionStorage.getItem('session')}}),

	putColumn: (id,obj) => api.put(sessionStorage.getItem('backend')+'/funnel/column/'+id,obj,{headers:{session:sessionStorage.getItem('session')}}),
 
	getFunnelAttributes: (id,obj) => api.get(sessionStorage.getItem('backend')+'/funnel/attributes/'+id,{headers:{session:sessionStorage.getItem('session')}}),

	postFunnelAttributes: (id,obj) => api.post(sessionStorage.getItem('backend')+'/funnel/attributes/'+id,obj,{headers:{session:sessionStorage.getItem('session')}}),

	postFunnelopportunity: (id,obj) => api.post(sessionStorage.getItem('backend')+'/funnel/opportunity/'+id,obj,{headers:{session:sessionStorage.getItem('session')}}),

	getOpportunityColumn: (id) => api.get(sessionStorage.getItem('backend')+'/funnel/opportunitycolumn/'+id,{headers:{session:sessionStorage.getItem('session')}}),

	getOpportunity: (id) => api.get(sessionStorage.getItem('backend')+'/funnel/opportunity/'+id,{headers:{session:sessionStorage.getItem('session')}}),
	 
	putSequenceOpportunity: (id,obj) => api.put(sessionStorage.getItem('backend')+'/funnel/opportunitycolumn/'+id,obj,{headers:{session:sessionStorage.getItem('session')}}),
	 
	//Para editar uma oportunidade
	putFunnelopportunity: (id,obj,extra) => api.put(sessionStorage.getItem('backend')+'/funnel/opportunity/'+id+(extra !== undefined ? extra:''),obj,{headers:{session:sessionStorage.getItem('session')}}),
 
	deleteOpportunity: (id) => api.delete(sessionStorage.getItem('backend')+'/funnel/opportunity/'+id,{headers:{session:sessionStorage.getItem('session')}}),

	deleteColumn: (id) => api.delete(sessionStorage.getItem('backend')+'/funnel/opportunitycolumn/'+id,{headers:{session:sessionStorage.getItem('session')}}),
 
	//Para cadastrar o funil
	postFunnel: (obj) => api.post(sessionStorage.getItem('backend')+'/funnel/',obj,{headers:{session:sessionStorage.getItem('session')}}),

	putFunnel: (id,obj) => api.put(sessionStorage.getItem('backend')+'/funnel/'+id,obj,{headers:{session:sessionStorage.getItem('session')}}),

	deleteFunnel: (id) => api.delete(sessionStorage.getItem('backend')+'/funnel/'+id,{headers:{session:sessionStorage.getItem('session')}}),
 
	//Para salvar photo client
	savePhotoClient: (client,obj) => api.put(sessionStorage.getItem('backend')+'/client/updatephoto/'+client,obj,{headers: {session: sessionStorage.getItem('session')}}),

	//Para savar photo student
	savePhotoStudent: (student,obj) => api.put(sessionStorage.getItem('backend')+'/student/updatephoto/'+student,obj,{headers: {session: sessionStorage.getItem('session')}}),

	//Para pegar os planos de pagamento
	getPlans: () => api.get(sessionStorage.getItem('backend')+'/plans/',{headers: {session: sessionStorage.getItem('session')}}),

	getPlans2: () => api.get(sessionStorage.getItem('backend')+'/plans2/',{headers: {session: sessionStorage.getItem('session')}}),


	//Para pegar os dados de uma oportunidade
	getOpportunity: (id) => api.get(sessionStorage.getItem('backend')+'/funnel/opportunity/'+id,{headers: {session: sessionStorage.getItem('session')}}),

	getOpportunityassignment: (id) => api.get(sessionStorage.getItem('backend')+'/funnel/assignment/'+id,{headers: {session: sessionStorage.getItem('session')}}),

	postOpportunityassignment: (obj) => api.post(sessionStorage.getItem('backend')+'/funnel/assignment/',obj,{headers: {session: sessionStorage.getItem('session')}}),

	putOpportunityassignment: (id,obj,extra) => api.put(sessionStorage.getItem('backend')+'/funnel/assignment/'+id+(extra !== undefined ? extra:''),obj,{headers: {session: sessionStorage.getItem('session')}}),

	deleteOpportunityassignment: (id) => api.delete(sessionStorage.getItem('backend')+'/funnel/assignment/'+id,{headers: {session: sessionStorage.getItem('session')}}),

	listOpportunityassignment: (id) => api.get(sessionStorage.getItem('backend')+'/funnel/assignmentlist/'+id,{headers: {session: sessionStorage.getItem('session')}}),
	
	getCourse: (id) => api.get(sessionStorage.getItem('backend')+'/course/'+id,{headers: {session: sessionStorage.getItem('session')}}),
	getCourseFromInvoice: (id) => api.get(sessionStorage.getItem('backend')+'/course/frominvoice/'+id,{headers: {session: sessionStorage.getItem('session')}}),

	
	changeAccBank: (data) => api.put(sessionStorage.getItem('backend')+'/configuration/changebank',data,{headers: {session: sessionStorage.getItem('session')}}),

	
	//Para pegar os dados no gateway
	dataGateway: (id) => api.get(sessionStorage.getItem('backend')+'/gateway/data',{headers: {session: sessionStorage.getItem('session')}}),

	logout: () => api.delete(sessionStorage.getItem('backend')+'/auth/logout',{headers:{session:sessionStorage.getItem('session')}}),

	downloadPDF: (id) => api.get(sessionStorage.getItem('backend')+'/invoice/donwload/'+id,{headers:{session:sessionStorage.getItem('session')}}),

	getInvoiceRoyal: (id) => api.get(sessionStorage.getItem('backend')+'/invoice/royal/',{headers:{session:sessionStorage.getItem('session')}}),

	getTransfers: () => api.get(sessionStorage.getItem('backend')+'/gateway/transfers/',{headers:{session:sessionStorage.getItem('session')}}),
	 
	postTransfers: (data) => api.post(sessionStorage.getItem('backend')+'/gateway/transfer/',data,{headers:{session:sessionStorage.getItem('session')}}),

	//para forçar a geração da fatura
	forceGenerateInvoice: (id) => api.post(sessionStorage.getItem('backend')+'/invoice/generateapi/'+id,{},{headers:{session:sessionStorage.getItem('session')}}),

	getHooks: (id) => api.get(sessionStorage.getItem('backend')+'/gateway/web_hooks/',{headers:{session:sessionStorage.getItem('session')}}),
 
	postHook: (data) => api.post(sessionStorage.getItem('backend')+'/gateway/web_hooks/',data,{headers:{session:sessionStorage.getItem('session')}}),

	putHook: (id,data) => api.put(sessionStorage.getItem('backend')+'/gateway/web_hooks/'+id,data,{headers:{session:sessionStorage.getItem('session')}}),
	
	deleteHook: (id) => api.delete(sessionStorage.getItem('backend')+'/gateway/web_hooks/'+id,{headers:{session:sessionStorage.getItem('session')}}),
	 
	putHooksToggle: (id) => api.put(sessionStorage.getItem('backend')+'/gateway/web_hooks/toggle/'+id,{headers:{session:sessionStorage.getItem('session')}}),

	getComissions: () => api.get(sessionStorage.getItem('backend')+'/comission/',{headers:{session:sessionStorage.getItem('session')}}),

	getInvoiceAllItems: () => api.get(sessionStorage.getItem('backend')+'/invoice/getallinvoiceitems/',{headers:{session:sessionStorage.getItem('session')}}),

	getWithdrawRequests: () => api.get(sessionStorage.getItem('backend')+'/gateway/withdraw_requests',{headers:{session:sessionStorage.getItem('session')}}),

	postTransfer: (data) => api.post(sessionStorage.getItem('backend')+'/withdrawalrequest/',data,{headers:{session:sessionStorage.getItem('session')}}),

	changeCheckComission: (id,data) => api.put(sessionStorage.getItem('backend')+'/comission/changecheck/'+id,data,{headers:{session:sessionStorage.getItem('session')}}),

	//Transfere o fatura para outra matrícula
	transferInvoiceRegistration: (invoice,data) => api.put(sessionStorage.getItem('backend')+'/invoice/transferreg/'+invoice,data,{headers:{session:sessionStorage.getItem('session')}}),

	syncInvoice: (invoice) => api.get(sessionStorage.getItem('backend')+'/invoice/iugusync/'+invoice,{headers:{session:sessionStorage.getItem('session')}}),

	search: (search) => api.get(sessionStorage.getItem('backend')+'/search?search='+search,{headers:{session:sessionStorage.getItem('session')}}),

	allPlans: () => api.get(sessionStorage.getItem('backend')+'/allplans',{headers:{session:sessionStorage.getItem('session')}}),
	plansAvailable: () => api.get(sessionStorage.getItem('backend')+'/plansavailable',{headers:{session:sessionStorage.getItem('session')}}),
	postPlansAvailable:  (data) => api.post(sessionStorage.getItem('backend')+'/plansavailable',data,{headers:{session:sessionStorage.getItem('session')}}),

	workprovider: () => api.get(sessionStorage.getItem('backend')+'/workprovider/',{headers:{session:sessionStorage.getItem('session')}}),
	savePhotoWorkProvider: (workprovider,obj) => api.put(sessionStorage.getItem('backend')+'/workprovider/updatephoto/'+workprovider,obj,{headers: {session: sessionStorage.getItem('session')}}),

	getWorkprovider: (id) => api.get(sessionStorage.getItem('backend')+'/workprovider/'+id,{headers:{session:sessionStorage.getItem('session')}}),
	deleteWorkprovider: (id) => api.delete(sessionStorage.getItem('backend')+'/workprovider/'+id,{headers:{session:sessionStorage.getItem('session')}}),
	putWorkprovider: (id,data) => api.put(sessionStorage.getItem('backend')+'/workprovider/'+id,data,{headers:{session:sessionStorage.getItem('session')}}),
	postWorkprovider: (data) => api.post(sessionStorage.getItem('backend')+'/workprovider/',data,{headers:{session:sessionStorage.getItem('session')}}),

	getWorkProviderCategories: () => api.get(sessionStorage.getItem('backend')+'/workprovider/categories',{headers:{session:sessionStorage.getItem('session')}}),

	postWorkProviderCategorie: (data) => api.post(sessionStorage.getItem('backend')+'/workprovider/categories',data,{headers:{session:sessionStorage.getItem('session')}}),
	putWorkProviderCategorie: (id,data) => api.put(sessionStorage.getItem('backend')+'/workprovider/categories/'+id,data,{headers:{session:sessionStorage.getItem('session')}}),
	
	deleteWorkProvider: (id) => api.delete(sessionStorage.getItem('backend')+'/workprovider/'+id,{headers:{session:sessionStorage.getItem('session')}}),
	
	deleteWorkProviderCategorie: (id) => api.delete(sessionStorage.getItem('backend')+'/workprovider/categories/'+id,{headers:{session:sessionStorage.getItem('session')}}),
	 

	getWkAnnotations: (id) => api.get(sessionStorage.getItem('backend')+'/workprovider/annotations/'+id,{headers:{session:sessionStorage.getItem('session')}}),
	getWkAnnotation: (id) => api.get(sessionStorage.getItem('backend')+'/workprovider/annotation/'+id,{headers:{session:sessionStorage.getItem('session')}}),
	putWkAnnotations: (id,data) => api.put(sessionStorage.getItem('backend')+'/workprovider/annotation/'+id,data,{headers:{session:sessionStorage.getItem('session')}}),
	postWkAnnotations: (data) => api.post(sessionStorage.getItem('backend')+'/workprovider/annotation/',data,{headers:{session:sessionStorage.getItem('session')}}),
	deleteWkAnnotations: (id) => api.delete(sessionStorage.getItem('backend')+'/workprovider/annotation/'+id,{headers:{session:sessionStorage.getItem('session')}}),
	
	getBoleto: (id) => api.get(sessionStorage.getItem('backend')+'/invoice/boletodev/'+id,{headers:{session:sessionStorage.getItem('session')}}),
	getReportGerencial: (params) => api.get(sessionStorage.getItem('backend')+'/report/gerencial'+params,{headers:{session:sessionStorage.getItem('session')}}),

	
	
	
	getProducsCenterOriginal: () => api.get(sessionStorage.getItem('backend')+'/productcenteroriginal',{headers:{session:sessionStorage.getItem('session')}}),




	getDistincProducts: () => api.get(sessionStorage.getItem('backend')+'/product_id_center_distinc',{headers:{session:sessionStorage.getItem('session')}}),
	getComissionsInterval: (params) => api.get(sessionStorage.getItem('backend')+'/report/comission'+params,{headers:{session:sessionStorage.getItem('session')}}),
	
	getFinancialReturn: (params) => api.get(sessionStorage.getItem('backend')+'/report/finalcialreturn'+params,{headers:{session:sessionStorage.getItem('session')}}),
	
	postComission: (data) => api.post(sessionStorage.getItem('backend')+'/comission/',data,{headers:{session:sessionStorage.getItem('session')}}),
	putComission: (id,data) => api.put(sessionStorage.getItem('backend')+'/comission/'+id,data,{headers:{session:sessionStorage.getItem('session')}}),

	getChartClassroomRegs: (y) => api.get(sessionStorage.getItem('backend')+'/widgets/classroomregs?y='+y,{headers:{session:sessionStorage.getItem('session')}}),


	getPlanOfplansofaccount: () => api.get(sessionStorage.getItem('backend')+'/plansofaccount',{headers:{session:sessionStorage.getItem('session')}}),


	postBilling: (data) => api.post(sessionStorage.getItem('backend')+'/billing',data,{headers:{session:sessionStorage.getItem('session')}}),

	putBilling: (id,data) => api.put(sessionStorage.getItem('backend')+'/billing/'+id,data,{headers:{session:sessionStorage.getItem('session')}}),

	deleteBilling: (id) => api.delete(sessionStorage.getItem('backend')+'/billing/'+id,{headers:{session:sessionStorage.getItem('session')}}),

	getBilling: (id) => api.get(sessionStorage.getItem('backend')+'/billing/'+id,{headers:{session:sessionStorage.getItem('session')}}),
	
	getAccounts: (start,end) => api.get(sessionStorage.getItem('backend')+'/billing/?start='+start+'&end='+end,{headers:{session:sessionStorage.getItem('session')}}),

	getFilesname: () => api.get(sessionStorage.getItem('backend')+'/billing/filesname',{headers:{session:sessionStorage.getItem('session')}}),

	insertFileBilling: (billing,obj) => api.post(sessionStorage.getItem('backend')+'/billing/'+billing+'/insertfile',obj,{headers:{session:sessionStorage.getItem('session')}}),

	removeFileBilling: (billing,name) => api.delete(sessionStorage.getItem('backend')+'/billing/'+billing+'/removefile?filename='+name,{headers:{session:sessionStorage.getItem('session')}}),

	getLicenses: () => api.get(sessionStorage.getItem('backend')+'/licenses',{headers:{session:sessionStorage.getItem('session')}}),

	 


 
	dlHtmlContract: (registration,contract) => api.get(sessionStorage.getItem('backend')+'/contract/generate?registration='+registration+'&contract='+contract,{headers:{session:sessionStorage.getItem('session')}}),

	 
	 





	//getChats: (id) => api.get(sessionStorage.getItem('backend')+'/wpp/getchats/'+id,{headers:{session:sessionStorage.getItem('session')}}),

	//checkStatusWpp: () => api.get(sessionStorage.getItem('backend')+'/wpp/checkstatus',{headers:{session:sessionStorage.getItem('session')}}),

	//wppDisconnect: (id) => api.get(sessionStorage.getItem('backend')+'/whatsapp/disconnect/'+id,{headers:{session:sessionStorage.getItem('session')}}),

	//getprofilepicurl: (idwpp,id) => api.get(sessionStorage.getItem('backend')+'/wpp/getprofilepicurl/'+idwpp+'/'+id,{headers:{session:sessionStorage.getItem('session')}}),

	//getMyInfowpp: (id) => api.get(sessionStorage.getItem('backend')+'/wpp/myinfo/'+id,{headers:{session:sessionStorage.getItem('session')}}),

	//listWpps: (id) => api.get(sessionStorage.getItem('backend')+'/whatsapp',{headers:{session:sessionStorage.getItem('session')}}),

	//postWpp: (obj) => api.post(sessionStorage.getItem('backend')+'/whatsapp',obj,{headers:{session:sessionStorage.getItem('session')}}),

	//putWpp: (id,obj) => api.put(sessionStorage.getItem('backend')+'/whatsapp/'+id,obj,{headers:{session:sessionStorage.getItem('session')}}),

	//deleteWpp: (id,obj) => api.delete(sessionStorage.getItem('backend')+'/whatsapp/'+id,{headers:{session:sessionStorage.getItem('session')}}),

	//getMessages: (idwpp,id) => api.get(sessionStorage.getItem('backend')+'/wpp/getmessages/'+idwpp+'/'+id,{headers:{session:sessionStorage.getItem('session')}}),

	//sendMessage: (idwpp,id,obj) => api.post(sessionStorage.getItem('backend')+'/wpp/postmessage/'+idwpp+'/'+id,obj,{headers:{session:sessionStorage.getItem('session')}}),

	downloadFileMessage: (idwpp,id,obj) => api.post(sessionStorage.getItem('backend')+'/wpp/download/'+idwpp+'/'+id,obj,{headers:{session:sessionStorage.getItem('session')}}),

	//getChatWpp: (idwpp,id) => api.get(sessionStorage.getItem('backend')+'/wpp/getchat/'+idwpp+'/'+id,{headers:{session:sessionStorage.getItem('session')}}),
	
	downloadInvoiceMerg: (obj) => api.post(sessionStorage.getItem('backend')+'/invoice/donwloadmerg/',obj,{headers:{session:sessionStorage.getItem('session')}}),

	//getWppHookList: (id) => api.get(sessionStorage.getItem('backend')+'/wpp/hooklist/'+id,{headers:{session:sessionStorage.getItem('session')}}),
	//getWppHook: (id) => api.get(sessionStorage.getItem('backend')+'/wpp/hook/'+id,{headers:{session:sessionStorage.getItem('session')}}),
	//postWppHook: (obj) => api.post(sessionStorage.getItem('backend')+'/wpp/hook/',obj,{headers:{session:sessionStorage.getItem('session')}}),
	//putWppHook: (id,obj) => api.put(sessionStorage.getItem('backend')+'/wpp/hook/'+id,obj,{headers:{session:sessionStorage.getItem('session')}}),
	//deleteWppHook: (id) => api.delete(sessionStorage.getItem('backend')+'/wpp/hook/'+id,{headers:{session:sessionStorage.getItem('session')}}),
	genLinkInvoice: (id) => api.get(sessionStorage.getItem('backend')+'/invoice/genlink/'+id,{headers:{session:sessionStorage.getItem('session')}}),

	checkPagseguro: (obj) => api.post(sessionStorage.getItem('backend')+'/pagseguro',obj,{headers:{session:sessionStorage.getItem('session')}}),
	 
	checkIntegrationLms: () => api.get(sessionStorage.getItem('backend')+'/configurationlmsapi/check',{headers:{session:sessionStorage.getItem('session')}}),

	getResponsibleTeachers: () => api.get(sessionStorage.getItem('backend')+'/apilms/responsibleteachers',{headers:{session:sessionStorage.getItem('session')}}),

	getInvoiceLogAction: (id) => api.get(sessionStorage.getItem('backend')+'/invoicelogaction/'+id,{headers:{session:sessionStorage.getItem('session')}}),


	genLmsRegistration: (id) => api.get(sessionStorage.getItem('backend')+'/apilms/genlms/'+id,{headers:{session:sessionStorage.getItem('session')}}),

	getReturnDates: (id) => api.get(sessionStorage.getItem('backend')+'/invoice/returndates/'+id,{headers:{session:sessionStorage.getItem('session')}}),

	 
	getLastContractLicense: (id) => api.get(sessionStorage.getItem('backend')+'/contract_license/last',{headers:{session:sessionStorage.getItem('session')}}),
	accLastContractLicense: (id) => api.get(sessionStorage.getItem('backend')+'/contract_license/acc',{headers:{session:sessionStorage.getItem('session')}}),

	getNotices: (limit) => api.get(sessionStorage.getItem('backend')+'/notice/last?limit='+limit,{headers:{session:sessionStorage.getItem('session')}}),


	noticeMarkRead: (id) => api.put(sessionStorage.getItem('backend')+'/notice/markread/'+id,{},{headers:{session:sessionStorage.getItem('session')}}),
	noticeMarkUnRead: (id) => api.put(sessionStorage.getItem('backend')+'/notice/markunread/'+id,{},{headers:{session:sessionStorage.getItem('session')}}),

	getInvoiceComission: (id) => api.get(sessionStorage.getItem('backend')+'/comission/invoice/'+id,{headers:{session:sessionStorage.getItem('session')}}),
	

	getApiFocus: () => api.get(sessionStorage.getItem('backend')+'/ntf/getbase',{headers:{session:sessionStorage.getItem('session')}}),

	saveApiFocus: (data) => api.put(sessionStorage.getItem('backend')+'/ntf/saveconfs',data,{headers:{session:sessionStorage.getItem('session')}}),
	checkApiFocus: () => api.get(sessionStorage.getItem('backend')+'/ntf/check',{headers:{session:sessionStorage.getItem('session')}}),

	productsServicesFocusConf: () => api.get(sessionStorage.getItem('backend')+'/ntf/getproductsservices',{headers:{session:sessionStorage.getItem('session')}}),

	putproductsServicesFocusConf: (data) => api.put(sessionStorage.getItem('backend')+'/ntf/putproductsservices',data,{headers:{session:sessionStorage.getItem('session')}}),

	getNfs: (invoice) => api.get(sessionStorage.getItem('backend')+'/nf/invoice/'+invoice,{headers:{session:sessionStorage.getItem('session')}}),

	getProductCenter: (id) => api.get(sessionStorage.getItem('backend')+'/productcenter/'+id,{headers:{session:sessionStorage.getItem('session')}}),

	emitServiceNf: (data) => api.post(sessionStorage.getItem('backend')+'/insertnfse',data,{headers:{session:sessionStorage.getItem('session')}}),
	emitProductNf: (data) => api.post(sessionStorage.getItem('backend')+'/insertnfe',data,{headers:{session:sessionStorage.getItem('session')}}),

	deleteServiceNf: (id) => api.delete(sessionStorage.getItem('backend')+'/nf/delete/'+id,{headers:{session:sessionStorage.getItem('session')}}),

	calcelServiceNf: (id,text) => api.delete(sessionStorage.getItem('backend')+'/nf/cancel/'+id+'?justificativa='+text,{headers:{session:sessionStorage.getItem('session')}}),
	 
	listnatureoperation: () => api.get(sessionStorage.getItem('backend')+'/nf/listnatureoperation',{headers:{session:sessionStorage.getItem('session')}}),
	 
	updateNf: (id) => api.get(sessionStorage.getItem('backend')+'/nf/update/'+id,{headers:{session:sessionStorage.getItem('session')}}), 

	searchNtfs: (order,status,search,limit,page,startDate,endDate) => api.get(sessionStorage.getItem('backend')+'/nf/search?page='+page+'&limit='+limit+'&search='+search+'&status='+status+'&order='+order+'&startDate='+startDate+'&endDate='+endDate,{headers:{session:sessionStorage.getItem('session')}}), 
	
	getMetaBase: () => api.get(sessionStorage.getItem('backend')+'/metabase',{headers:{session:sessionStorage.getItem('session')}}), 
	
	getCancelReasons: () => api.get(sessionStorage.getItem('backend')+'/registration/listcancelreason',{headers:{session:sessionStorage.getItem('session')}}), 

	putCancelRegistration: (id,data) => api.put(sessionStorage.getItem('backend')+'/registration/cancel/'+id,data,{headers:{session:sessionStorage.getItem('session')}}), 

	putRevertCancelRegistration: (id) => api.put(sessionStorage.getItem('backend')+'/registration/revertcancel/'+id,{},{headers:{session:sessionStorage.getItem('session')}}), 
	 


	testWppConf: () => api.get(sessionStorage.getItem('backend')+'/wpp/test',{headers:{session:sessionStorage.getItem('session')}}),

	getWppConf: () => api.get(sessionStorage.getItem('backend')+'/wpp/conf',{headers:{session:sessionStorage.getItem('session')}}),
	saveWpp: (obj) => api.put(sessionStorage.getItem('backend')+'/wpp',obj,{headers:{session:sessionStorage.getItem('session')}}),

	getWpp: () => api.get(sessionStorage.getItem('backend')+'/wpp',{headers:{session:sessionStorage.getItem('session')}}),
	 

	getInvoicesWorkprovider: (id) => api.get(sessionStorage.getItem('backend')+'/workprovider/invoices/'+id,{headers:{session:sessionStorage.getItem('session')}}),



 

	getAddonsReg: (id) => api.get(sessionStorage.getItem('backend')+'/registration_addonsrg/'+id,{headers:{session:sessionStorage.getItem('session')}}),
	postAddonsReg: (data) => api.post(sessionStorage.getItem('backend')+'/registration_addons/',data,{headers:{session:sessionStorage.getItem('session')}}),
	putAddonsReg: (id,data) => api.put(sessionStorage.getItem('backend')+'/registration_addons/'+id,data,{headers:{session:sessionStorage.getItem('session')}}),
	deleteAddonsReg: (id) => api.delete(sessionStorage.getItem('backend')+'/registration_addons/'+id,{headers:{session:sessionStorage.getItem('session')}}),


	postWebhook: (data) => api.post(sessionStorage.getItem('backend')+'/webhook',data,{headers:{session:sessionStorage.getItem('session')}}),
	putWebhook: (id,data) => api.put(sessionStorage.getItem('backend')+'/webhook/'+id,data,{headers:{session:sessionStorage.getItem('session')}}),
	deleteWebhook: (id) => api.delete(sessionStorage.getItem('backend')+'/webhook/'+id,{headers:{session:sessionStorage.getItem('session')}}),
	getWebhook: () => api.get(sessionStorage.getItem('backend')+'/webhook',{headers:{session:sessionStorage.getItem('session')}}),

	sendinvoicemail: (id) => api.get(sessionStorage.getItem('backend')+'/sendinvoicemail/'+id,{headers:{session:sessionStorage.getItem('session')}}),

	invoiceSimple: (data) => api.post(sessionStorage.getItem('backend')+'/invoice3/simple',data,{headers:{session:sessionStorage.getItem('session')}}),

	paidInvoice: (id) => api.put(sessionStorage.getItem('backend')+'/invoice/'+id+'/paid/',{},{headers:{session:sessionStorage.getItem('session')}}),

}

export default apis;