import React from "react";
import ReactDOM from "react-dom";
import {Provider} from 'react-redux';
import store from './store';
import App from './App';
import Swal from 'sweetalert2';

//import axios from "axios";

// plugins styles from node_modules
import "react-notification-alert/dist/animate.css";
import "react-perfect-scrollbar/dist/css/styles.css";

// plugins styles downloaded

import "./assets/vendor/sweetalert2/dist/sweetalert2.min.css";

import "./assets/vendor/fullcalendar/dist/fullcalendar.min.css";
import "./assets/vendor/select2/dist/css/select2.min.css";
import "./assets/vendor/quill/dist/quill.core.css";

import "./assets/vendor/nucleo/css/nucleo.css";
import "./assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";

// core styles
import "./assets/scss/argon-dashboard-pro-react.scss?v1.0.0";

import "./assets/css/animate.css";
import "./assets/css/hover.css";

//marlon styles
import "./assets/css/custom.css";
import "./assets/css/login.css";
import "./assets/css/alert.css";
import "./assets/css/creditCard.css";
import './assets/scss/global.scss';
import "reactjs-popup/dist/index.css";

window.swal = Swal;

//Campo dos usuários
window.clientExtraField = [
    
    {id:1,name:"Texto"},
    {id:2,name:"Inteiro"},
    {id:3,name:"Data"},
    {id:4,name:"Dinheiro"},
    {id:5,name:"Lista"},
    {id:6,name:"RG"},
    {id:7,name:"CPF"},
    {id:8,name:"Telefone ou Celular"},
    {id:9,name:"CEP"},
    {id:10,name:"Texto longo"},
    {id:11,name:"Arquivo"}

];


window.tipoFatura = [

    {name:"Matrícula",comicao:"10",
    
        products:[
            {name:"Introdução a Programação com Blockly-Phaser",
            items:[
                {description:"Taxa de matrícula",quantity:"1",price_cents:"13500"},
                {description:"Curso Regular - Faze 0",quantity:"1",price_cents:"75000"}
            ],
            totalFaturas:'12'
        },
        {name:"Fase 0 - No cartão",
                    items:[
                        {description:"Taxa de matrícula",quantity:"1",price_cents:"12000"},
                        {description:"Curso Regular - Faze 0",quantity:"1",price_cents:"75000"}
                    ],
                    totalFaturas:'12'
                }

            ]
            
    },

];

 


var data = [];
data.push({id:1,name_1:'Pai',name_2:"Mãe",lg_1:'Filho',lg_2:'Filha'}); 
data.push({id:2,name_1:'Tio',name_2:"Tia",lg_1:'Sobrinho',lg_2:'Sobrinha'}); 
data.push({id:3,name_1:'Avô',name_2:"Avó",lg_1:'Neto',lg_2:'Neta'});
data.push({id:4,name_1:'Sou eu',name_2:"Sou eu",lg_1:'Sou eu',lg_2:'Sou eu'});
window.parentage = data;


var data2 = [];
data2.push({id:0,name:"Segunda-feira"});
data2.push({id:1,name:"Terça-feira"});
data2.push({id:2,name:"Quarta-feira"});
data2.push({id:3,name:"Quinta-feira"});
data2.push({id:4,name:"Sexta-feira"});
data2.push({id:5,name:"Sábado"});
data2.push({id:6,name:"Domingo"});
window.diasSemanas = data2;

ReactDOM.render(<Provider store={store}><App/></Provider>,document.getElementById("root"));