import { combineReducers } from 'redux';

import user from './user/reducer';
import language from './language/reducer';
import accesslevel from './accesslevel/reducer';
import translate from './translate/reducer';
import permission from './permission/reducer';
import magictools from './magictools/reducer';

import unit from './unit/reducer';
import lesson from './lesson/reducer';
import course from './course/reducer';
import company from './company/reducer';
import useronline from './useronline/reducer';
import chat from './chat/reducer';

export default combineReducers({chat,user,language,accesslevel,translate,permission,magictools,unit,lesson,course,company,useronline});