import React from 'react';

class GlobalFunctions extends React.Component {

    constructor(props){

        super(props);
        
        this.geraLista = this.geraLista.bind(this);
        this.geraPonts = this.geraPonts.bind(this);
        this.geraOps = this.geraOps.bind(this);
        this.geraOpsObj = this.geraOpsObj.bind(this);

        window.geraLista = this.geraLista;
        window.geraPonts = this.geraPonts;
        window.geraOps = this.geraOps;
        window.geraOpsObj = this.geraOpsObj;
        window.methodPayment = this.methodPayment;
        
		window.globalfcs = this;

    }


    methodPayment(id){

        if(parseInt(id) === 1){ return 'Boleto'; }
        if(parseInt(id) === 2){ return 'Cartão de crédito'; }
        if(parseInt(id) === 3){ return 'Pix'; }
        if(parseInt(id) === 4){ return 'Todos'; }
        if(parseInt(id) === 5){ return 'Pagseguro'; }

        return '';

    }


    geraLista(array,sum,ops){

        var trace = "";
        if(sum === undefined){ sum = ''; trace = ""; }else{ trace = "-"; }

        return array.map((value,index) => {
    
            return(
            <React.Fragment key={'gera_lista_dda_'+value.id}> 
            <option value={value.id}>{window.geraPonts(sum+''+(index+1))} {value.name}</option>
            {
            
                this.geraLista(value.subs,(sum+trace+(index+1)),'SUBS')
            
            }
            </React.Fragment>)
            
        })
    
    }
    
    geraPonts(txt){
        
        txt = txt.split('-');
        var txt2 = "";
        for(let index = 0; index < txt.length; index++) {
            
            txt2 = txt2+(index !== 0 ? ".":"")+(txt[index]);
            
        }

        return txt2;
    
    }

    geraOps(array,sum){

        var trace = "";
        if(sum === undefined){ sum = ''; trace = ""; }else{ trace = "-"; }

        return array.map((value,index) => {
 
            return(
            <React.Fragment key={'geraOps_dd_'+value.id}>
                
                {

                    <option value={value.id}>{this.geraPonts(sum+trace+(index+1))} {value.name}</option> 
                
                }
                
                {this.geraOps(value.subs,(sum+trace+(index+1)),'SUBS')}
            </React.Fragment>)
            
        })

    }

    geraOpsObj(array,sum){

        var trace = "";
        if(sum === undefined){

            window.listObjOpsPLan = [];

        }
        

        if(sum === undefined){ sum = ''; trace = ""; }else{ trace = "-"; }

        for(let index = 0; index < array.length; index++) {
            
            var value = array[index];
            //obj.push({id:value.id,name:value.name});
            window.listObjOpsPLan.push({id:value.id,name:value.name});

            console.log('deu push obj '+value.id);

            this.geraOpsObj(value.subs,(sum+trace+(index+1)),'SUBS');

            
        }

        /*array.map((value,index) => {
            
            console.log('deu push obj '+value.id);
            obj.push(value);

            
 
        });*/

        return window.listObjOpsPLan;

    }
    
	render(){

		return(<React.Fragment></React.Fragment>)

	}

}

export default GlobalFunctions;