import React, { Suspense, lazy } from "react";
import api from "./../Api.js";
import NotificationAlert from "react-notification-alert";
import { Sugar } from 'react-preloaders2';
//Redirect
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import $ from "jquery";
import Login from "./pages/examples/Login.jsx";
import { ToastProvider } from 'react-toast-notifications'
import ReactPixel from 'react-facebook-pixel';
//import AdminLayout from "./../layouts/Admin.jsx";


//import Live from "./pages/registrationOnline/Index.jsx";
//import BoardForm from "./pages/BoardForm.jsx";
//import ExternalForm from "./pages/ExternalForm.jsx";

import Cookies from 'universal-cookie';
import LoadPage from "../components/LoadPage.jsx";
import axios from "axios";

const AdminLayout = lazy(() => import('./../layouts/Admin.jsx'));
const Live = lazy(() => import('./pages/registrationOnline/Index.jsx'));
const PaySimple = lazy(() => import('./pages/SimplePlay/PaySimple.jsx'));
 
const ExternalForm = lazy(() => import('./pages/ExternalForm.jsx'));


const cookies = new Cookies();




class Main extends React.Component {

	constructor(props) {

		super(props);
		this.state = {
			units: undefined,
		};

		this.loginSuccess = this.loginSuccess.bind(this);
		this.successMsg = this.successMsg.bind(this);
		this.dangerMsg = this.dangerMsg.bind(this);

		window.main = this;

		window.successMsg = this.successMsg;
		window.dangerMsg = this.dangerMsg;


	}

	loginSuccess(session, user) {

		sessionStorage.setItem('session', session);
		sessionStorage.setItem('userId', user.id);

		var domain = sessionStorage.backend.split('//')[1];
		console.log('domain dds:' + domain);

		cookies.set('session', session, { path: '/' });
		cookies.set('session', session, { path: '/', domain: domain });
		cookies.set('userId', user.id, { path: '/' });

		this.props.setAppState({ session: session, userId: user.id, user: user });

	}

	async componentDidMount() {


		

		$("head").append('<link rel="icon" href="' + sessionStorage.getItem('backend') + window.app.state.confs.favicon + '"></link>');
		setTimeout(() => { $("body").attr('style', ''); }, 1000);
		setTimeout(() => { $("body").attr('style', ''); }, 2000);
		setInterval(() => { $("body").attr('style', ''); }, 10000);

	}


	async successMsg(msg) {

		let options = { place: "tr", message: (<div className="alert-text"><span className="alert-title" data-notify="title">Sucesso!</span><span data-notify="message">{msg}</span></div>), type: 'success', icon: "fas fa-exclamation", autoDismiss: 7 };
		this.refs.notificationAlert.notificationAlert(options);

	}

	async dangerMsg(msg) {

		let options = { place: "tr", message: (<div className="alert-text"><span className="alert-title" data-notify="title">Ops!</span><span data-notify="message">{msg}</span></div>), type: 'danger', icon: "fas fa-exclamation", autoDismiss: 7 };
		this.refs.notificationAlert.notificationAlert(options);

	}


	render() {

		const loginSuccess = this.loginSuccess;
		if (this.refs !== undefined) {

			if (this.refs.notificationAlert !== undefined) {

				window.notificationAlert = this.refs.notificationAlert.notificationAlert;

			}

		}

		/*
		if(window.location.pathname === "/live" || window.location.pathname === "/live/"){

			return(<React.Fragment><Live/></React.Fragment>);

		}

		if(window.location.pathname === "/presential" || window.location.pathname === "/presential/"){

			return(<React.Fragment><Live presential={true} /></React.Fragment>);

		}*/

		if (window.location.pathname.indexOf("/paysimpleb2b") !== -1) {

			return (<React.Fragment><Suspense fallback={<h1 hidden>Carregando...</h1>}><Router><Route path="/paysimpleb2b/:alias" render={(props) => <PaySimple {...props} pj={true} />} /></Router></Suspense></React.Fragment>);
			
		}

		if (window.location.pathname.indexOf("/paysimpleb2c") !== -1) {

			return (<React.Fragment><Suspense fallback={<h1 hidden>Carregando...</h1>}><Router><Route path="/paysimpleb2c/:alias" render={(props) => <PaySimple {...props} pj={false} />} /></Router></Suspense></React.Fragment>);
			
		}

		 

		if (window.location.pathname.indexOf("/live") !== -1) {

			return (<React.Fragment><Suspense fallback={<h1 hidden>Carregando...</h1>}><Router><Route path="*" render={(props) => <Live {...props} />} /></Router></Suspense></React.Fragment>);

		}

		if (window.location.pathname.indexOf("/presential") !== -1) {

			return (<React.Fragment><Suspense fallback={<LoadPage name="Cursos" />}><Router><Route path="*" render={(props) => <Live {...props} presential={true} />} /></Router></Suspense></React.Fragment>);

		}

		if (window.location.pathname.indexOf("/subscription") !== -1) {

			return (<React.Fragment><Suspense fallback={<LoadPage name="Cursos" />}><Router><Route path="*" render={(props) => <Live {...props} subscription={true} />} /></Router></Suspense></React.Fragment>);

		}

		if (window.location.pathname.indexOf("/form") !== -1) {

			return (<React.Fragment><Suspense fallback={<h1 hidden>Carregando...</h1>}><ExternalForm /></Suspense></React.Fragment>);

		}

		return (
			<React.Fragment>
				 
				<div className="rna-wrapper"><NotificationAlert ref="notificationAlert" /></div>
 
				<title>{window.app.state.confs.systemName}</title>
				<ToastProvider autoDismissTimeout={10000} >
					<Router>
						{
							//Caso tenha session o storage
							(window.app.state.confs.license.status === 1 && window.app.state.user !== undefined) &&
							<Suspense fallback={<Sugar customLoading={true} color={window.app.state.confs.headerDegrade1} />}>
								<Route path="*" render={(props) => <AdminLayout {...props} />} />
							</Suspense>

						}
						{
							//Caso o session no app esteja em branco, exibe a tela de login
							(window.app.state.confs.license.status === 0 || window.app.state.user === undefined) &&
							<React.Fragment>
								<Switch>
									<Route path="*" render={(props) => <Login {...{ loginSuccess }} />} />
									{/*<Redirect to="/" /> */}
								</Switch>
							</React.Fragment>

						}
					</Router>
				</ToastProvider>

				<style>{' .menu_left_ddd .nav-item.active{background-color: ' + window.app.state.confs.headerDegrade1 + ';}'}</style>

			</React.Fragment>);

	}

}

export default connect(state => ({
	languages: state.language,
	translate: state.translate,
	accesslevel: state.accesslevel,
	permission: state.permission,
	user: state.user
}))(Main);
