import { useContext, createContext, useCallback, useRef } from "react";

const DataInfosContext = createContext(null);


const DataStateInfos = ({ children }) => {


    const listWeek = useRef([
        {
            id: 0,
            name: "Segunda"
        },
        {
            id: 1,
            name: "Terça"
        },
        {
            id: 2,
            name: "Quarta"
        },
        {
            id: 3,
            name: "Quinta"
        },
        {
            id: 4,
            name: "Sexta"
        },
        {
            id: 5,
            name: "Sábado"
        },
        {
            id: 6,
            name: "Domingo"
        }
    ])

    const getWeekDays = useCallback((id) => {

        if (id.length === 1) {
            return listWeek.current.find(x => parseInt(x.id) === parseInt(id[0]));
        }

        if (id.length > 1) {
            let getWeeksArrays = '';

            listWeek.current.filter(x => id.includes(x.id)).forEach((x, index) => getWeeksArrays = getWeeksArrays + (index > 0 ? ' - ' : '') + x.name);
            console.log('listWeek -> ', getWeeksArrays);
            return { name: getWeeksArrays };

        }

        return { name: 'ID não informado.' };

    }, []);


    return (
        <DataInfosContext.Provider
            displayName="Data Infos"
            value={
                {
                    getWeekDays,
                    listWeek
                }
            }>
            {children}
        </DataInfosContext.Provider>
    )
}

export default function useDataInfos() {

    return useContext(DataInfosContext);
}

export { DataStateInfos }